import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	transparentButtonContainer: {
		':hover': {
			cursor: 'pointer',
		},
		background: 'none',
		border: 'none',
		outline: 'none',
	},
});
