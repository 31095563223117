import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

export const TransparentButton: React.FC<
	React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ className = '', children, ...restProps }) => {
	return (
		<button {...restProps} className={`${css(styleSheet.transparentButtonContainer)} ${className}`}>
			{children}
		</button>
	);
};
